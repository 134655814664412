import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import MetaTags from '@components/MetaTags';
import { HomePageHero } from '@ecosystems/homepage2/Hero';
import { VideoGenresSection } from '@ecosystems/homepage2/VideoGenresSection';
import { YogobeFeaturesSection } from '@ecosystems/homepage2/YogobeFeaturesSection';
import { CoursesSection } from '@ecosystems/homepage2/CoursesSection';
import { FeaturesSection } from '@ecosystems/homepage2/FeaturesSection';
import { OtherYogobeFeaturesSection } from '@ecosystems/homepage2/OtherYogobeFeatures';
import { MobileAppSection } from '@ecosystems/homepage2/MobileAppSection';
import pricingSection from '@ecosystems/pricing/PricingSection';
import {
  StartOverviewDocument,
  StartOverviewQuery,
  useStartOverviewQuery,
} from '@gql/generated';
import LanguageContext from '@lib/contexts/languageContext';
import { CampaignSection } from '@ecosystems/homepage2/CampaignSection';
import { BundlesSection } from '@ecosystems/homepage2/BundlesSection';

const PricingSection = styled(pricingSection)`
  && {
    background: none;
  }
`;

const HomePage = props => {
  const { pageProps } = props;
  const lang = useContext(LanguageContext);
  const { data, refetch } = useStartOverviewQuery({
    ssr: false,
  });

  useEffect(() => {
    refetch();
  }, [lang]);

  const startOverview = (data?.startOverview ??
    pageProps?.ssrData?.startOverview) as StartOverviewQuery['startOverview'];

  if (!startOverview) {
    return null;
  }

  return (
    <>
      <MetaTags
        title={startOverview.metaSection.title}
        description={startOverview.metaSection.description}
        thumbnailUrl={startOverview.metaSection.imageUrl}
      />
      <HomePageHero
        data={startOverview.heroSection}
        authenticated={!!props.currentUser}
      />
      <YogobeFeaturesSection data={startOverview.videoLibrarySection} />
      <CampaignSection data={startOverview.campaignSection} />
      <FeaturesSection
        data={startOverview.featuresSection}
        authenticated={!!props.currentUser}
      />
      <VideoGenresSection data={startOverview.genresSection} />
      <CoursesSection data={startOverview.coursesSection} />
      <CampaignSection data={startOverview.campaignSection2} />
      <BundlesSection bundles={startOverview.highlightedBundles} />
      <OtherYogobeFeaturesSection data={startOverview.otherFeaturesSection} />
      <CampaignSection data={startOverview.campaignSection3} reversed={true} />
      <MobileAppSection
        id="mobile_section"
        data={startOverview.mobileAppSection}
      />
      <PricingSection id="pricing_section" />
    </>
  );
};

export const getServerSideProps = async ctx => {
  const { apolloClient } = ctx.req;

  try {
    const data = await apolloClient.query({
      query: StartOverviewDocument,
    });

    return {
      props: { ssrData: data.data },
    };
  } catch (ex) {
    console.error(ex);
    //
  }
  return {
    props: {},
  };
};

export default HomePage;
