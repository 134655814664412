import { useContext } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { ArrowRightIcon } from '@assets/index';
import { Button } from '@components/ButtonV2';
import LanguageContext from '@lib/contexts/languageContext';
import BundleCard from '@organisms/cards/BundleCard';
import GenericSwipeSection from '@organisms/sections/GenericSwipeSection';

const Section = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 30px auto;
  overflow: hidden;

  &:empty {
    display: none;
  }

  .swiper {
    overflow: unset;
  }
`;

const ViewAllButton = styled(Button)`
  font-size: 14px;
  padding: 0;

  svg {
    width: 16px;
    margin-left: 5px;
  }
`;

export const BundlesSection = ({ bundles }) => {
  const lang = useContext(LanguageContext);

  return (
    <Section>
      <GenericSwipeSection
        sectionId="bundles"
        title={<Trans id="homepage.bundles_section.title" />}
        data={bundles}
        renderItem={({ item }) => <BundleCard key={item.slug} bundle={item} />}
        totalCount={null}
        viewAllCardText={<Trans id="homepage.bundles_section.view_all" />}
        viewAllURL={{ pathname: `/${lang}/needs-and-themes` }}
        viewAllButton={
          <ViewAllButton
            href={`/${lang}/needs-and-themes`}
            appearance="dark"
            link
          >
            <span>
              <Trans
                id="homepage.bundles_section.view_all"
                message="View all bundles"
              />
            </span>
            <ArrowRightIcon name="ygb-icon-Back-arrow-ic" fontSize="14px" />
          </ViewAllButton>
        }
        onViewAllClick={() => {
          //
        }}
        slidesPerView={3}
        group={3}
      />
    </Section>
  );
};
