import { useContext } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { Trans } from '@lingui/react';
import { Box, Container, Flex } from '@components/layout/Grid';
import Icon from '@components/Icon';
import { OtherTypeKind, StartOverviewQuery } from '@gql/generated';
import LanguageContext from '@lib/contexts/languageContext';
import { Button } from '@components/ButtonV2';

const Grid = styled.div`
  --margin-right: 12px;
  /*  */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px calc(-1 * var(--margin-right)) 0 0;
`;

const Anchor = styled(Link)`
  --columns: 2;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    --columns: 3;
  }

  width: calc((100% - var(--margin-right) * var(--columns)) / var(--columns));
  text-decoration: none;
  margin: 0 var(--margin-right) 20px 0;
`;

const Title = styled.h2`
  max-width: 620px;
  color: #243240;
  font-size: 22px;
  font-weight: bold;
  line-height: 34px;
  text-align: center;
  margin: 0 0 15px;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    font-size: 28px;
  }
`;

const SubTitle = styled.p`
  max-width: 620px;
  color: #243240;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    font-size: 18px;
  }
`;

const Card = styled.div`
  transform: scale(1);
  transition: transform 200ms ease-in-out;

  :hover {
    transform: scale(1.02);
  }
`;

const CardImage = styled.img`
  width: 100%;
  aspect-ratio: 1.5;
  border-radius: 4px;
  background-color: #cecece;
`;

const CardTitle = styled.h3`
  color: #243240;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 10px;

  i {
    display: inline-block;
    margin-left: 5px;
    transform: rotateY(180deg);
  }

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    font-size: 18px;
  }
`;

const CardDesc = styled.p`
  color: #242424;
  font-size: 13px;
  line-height: 1.4;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    font-size: 15px;
  }
`;

const PricingButton = styled(Button)`
  font-size: 18px;
  border-bottom: 1px solid currentColor;
  border-radius: 0px;
  padding-bottom: 2px;
`;

const Feature = ({ className = '', title, desc, image }) => {
  return (
    <Card className={className}>
      <CardImage src={image} />
      <Box pt={20} pb={10}>
        <CardTitle>
          {title} <Icon name="ygb-icon-Back-arrow-ic" fontSize="10px" />
        </CardTitle>
        <CardDesc>{desc}</CardDesc>
      </Box>
    </Card>
  );
};

const LINKS = {
  [OtherTypeKind['Videos']]: 'videos/genres/all',
  [OtherTypeKind['Programs']]: 'programs',
  [OtherTypeKind['Live']]: 'live',
  [OtherTypeKind['Challenges']]: 'challenge',
  [OtherTypeKind['Playlists']]: 'playlists',
  [OtherTypeKind['Bundles']]: 'needs-and-themes',
} as const;

type Props = {
  data: StartOverviewQuery['startOverview']['videoLibrarySection'];
};

export const YogobeFeaturesSection = (props: Props) => {
  const lang = useContext(LanguageContext);
  const {
    data: { title, description, videosLibrary },
  } = props;

  return (
    <Container
      flexDirection="column"
      alignItems="center"
      px={[20, 20, 20]}
      my={35}
    >
      <Title>{title}</Title>
      <SubTitle>{description}</SubTitle>
      <Grid>
        {videosLibrary.map((item, i) => {
          const href = LINKS[item.otherType]
            ? `/${lang}/${LINKS[item.otherType]}`
            : '#';
          return (
            <Anchor key={i} href={href}>
              <Feature
                title={item.title}
                desc={item.description}
                image={item.imageUrl}
              />
            </Anchor>
          );
        })}
      </Grid>
      <Flex justifyContent="center" alignItems="center" mt={20}>
        <PricingButton href={`/${lang}/pricing`} link>
          <Trans id="homepage.features_section.our_pricing_btn" /> &nbsp;
          <Icon name="ygb-icon-chevron-right" />
        </PricingButton>
      </Flex>
    </Container>
  );
};
