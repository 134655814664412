import styled, { useTheme } from 'styled-components';
import { Trans } from '@lingui/react';
import { useMedia } from 'use-media';
import { Button } from '@components/ButtonV2';
import { HeroSection } from '@gql/generated';
import { useContext } from 'react';
import LanguageContext from '@lib/contexts/languageContext';
import { UserContext } from '@lib/contexts/UserProvider';
import SvgCheckmark from '@assets/Checkmark';
import RightArrowIcon from '@assets/ArrowRightIcon';
import { Box } from '@components/layout/Grid';
import Icon from '@components/Icon';

const Section = styled.section<{ bg: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  // 76px for header
  height: calc(100vh - 76px);
  padding: 10% max(calc((100vw - 1240px) / 2), 20px) 0;
  margin-bottom: 30px;
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: center;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #242424;
    z-index: 1;
  }

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    min-height: 80vh;
    max-height: 640px;
    padding: 10% max(calc((100vw - 1240px) / 2), 20px);
  }
`;

const Content = styled.div`
  flex: 1;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  z-index: 1;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  z-index: -1;
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
  line-height: 1.4em;
  margin: 0 0 0.8em;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    font-size: 42px;
  }
`;

const SubTitle = styled.h2`
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4em;
  margin: 0 0 0.9em;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    font-size: 28px;
  }
`;

const Actions = styled.div`
  display: flex;
  margin-bottom: 10px;

  & > * {
    margin-right: 20px;
    margin-top: 10px;
  }
`;

const Disclaimer = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin: 0 0 10px;
  opacity: 0.8;
`;

const Bullets = styled.ul`
  width: calc(100% + 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  list-style: none;
  padding: 14px 20px;
  margin: 0 -20px;
  background-color: rgba(17, 158, 221, 0.2);
  z-index: 1;

  li {
    display: flex;
    align-items: center;
    margin-top: 10px;

    svg {
      fill: white;
      height: 14px;
      margin-right: 10px;
      margin-top: 4px;
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: 16px;
    font-style: normal;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;

    li {
      margin-top: 0;
      margin-right: 15px;
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    li {
      margin-right: 50px;
    }
  }
`;

const RightArrow = styled(RightArrowIcon)`
  width: 16px;
  margin-top: 2px;
  margin-left: 10px;

  path {
    fill: white;
  }
`;

type Props = {
  data: Pick<
    HeroSection,
    'title' | 'description' | 'imageUrl' | 'smallNote' | 'mobileImageUrl'
  >;
  authenticated?: boolean;
};

export const HomePageHero = (props: Props) => {
  const lang = useContext(LanguageContext);
  const theme = useTheme();
  const isDesktop = useMedia(`(min-width: ${theme.viewports.tablet}px)`);
  const [currentUser, loading] = useContext(UserContext);

  const {
    authenticated,
    data: { title, description, imageUrl, smallNote, mobileImageUrl },
  } = props;

  const showRegistration = !authenticated || (!currentUser && !loading);

  const actions = [];
  if (showRegistration) {
    actions.push(
      <Button
        key="0"
        appearance="blue"
        href={`/${lang}/register/account`}
        rounded
      >
        <Trans id="homepage.sections.register_btn" />
      </Button>
    );
  } else if (!currentUser?.isPremium && currentUser?.trial) {
    actions.push(
      <Button
        key="0"
        appearance="blue"
        href={`/${lang}/get-started/trial`}
        rounded
      >
        <Trans id="metadata.start_trial_btn" />
      </Button>
    );
  }

  if (['se', 'en'].includes(lang)) {
    actions.push(
      <Button
        key="1"
        href="/se/pricing"
        {...(showRegistration
          ? { appearance: 'white', outline: true, rounded: true }
          : { appearance: 'blue', rounded: true })}
      >
        <Trans id="homepage.hero_section.pricing_btn" />
      </Button>
    );
  }

  return (
    <Section
      id="hero"
      bg={!isDesktop && mobileImageUrl ? mobileImageUrl : imageUrl}
    >
      {/* <Image src={!isDesktop && mobileImageUrl ? mobileImageUrl : imageUrl} /> */}
      <Content>
        <Disclaimer>{smallNote}</Disclaimer>
        <Title>{title}</Title>
        <SubTitle>{description}</SubTitle>
        <Actions>{actions}</Actions>
        <Box>
          <Button key="1" appearance="white" href="/se/friskvardsbidrag" link>
            <Trans id="homepage.hero_section.wellness_btn" /> <RightArrow />
          </Button>
        </Box>
      </Content>
      <Bullets>
        <li>
          <SvgCheckmark /> <Trans id="landingpage.hero.bullet1" />
        </li>
        <li>
          <SvgCheckmark />
          <Trans id="landingpage.hero.bullet2" />
        </li>
        <li>
          <SvgCheckmark />
          <Trans id="landingpage.hero.bullet3" />
        </li>
      </Bullets>
    </Section>
  );
};
