import styled from 'styled-components';
import { Container } from '@components/layout/Grid';
import hero from '@ecosystems/landing_pages/hero/Hero';
import { Button as button } from '@components/ButtonV2';
import {
  VideoWrapper,
  Title as HeroTitle,
} from '@ecosystems/landing_pages/hero/components';
import Icon from '@components/Icon';
import { StartOverviewQuery } from '@gql/generated';

const Hero = styled(hero)`
  margin-top: 40px;

  ${HeroTitle} {
    font-size: 20px;
    margin-bottom: 0;
  }

  ${VideoWrapper} {
    img {
      max-height: 460px;
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
      overflow: hidden;
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    ${HeroTitle} {
      font-size: 26px;
      margin-bottom: 20px;
    }
  }
`;

const Title = styled.h2`
  max-width: 620px;
  color: #243240;
  font-size: 22px;
  font-weight: bold;
  line-height: 34px;
  text-align: center;
  margin: 0 0 15px;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    font-size: 28px;
  }
`;

const SubTitle = styled.p`
  max-width: 620px;
  color: #243240;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    font-size: 18px;
  }
`;

const Button = styled(button)`
  display: flex;
  align-items: center;

  i {
    display: inline-block;
    transform: rotate(180deg);
    margin-left: 6px;
  }
`;

type Props = {
  data: StartOverviewQuery['startOverview']['coursesSection'];
};

export const CoursesSection = (props: Props) => {
  const {
    data: {
      title,
      description,
      group1Title,
      group1Description,
      group1ButtonLink,
      group1ButtonText,
      group1ImageUrl,
      group2Title,
      group2Description,
      group2ButtonLink,
      group2ButtonText,
      group2ImageUrl,
    },
  } = props;
  return (
    <Container
      flexDirection="column"
      alignItems="center"
      px={[20, 20, 20]}
      my={35}
    >
      <Title>{title}</Title>
      <SubTitle>{description}</SubTitle>
      <Hero
        image={group1ImageUrl}
        title={group1Title}
        titleAs="h3"
        description={group1Description}
        actions={
          group1ButtonLink
            ? [
                <Button key="0" href={group1ButtonLink} appearance="blue" link>
                  {group1ButtonText}{' '}
                  <Icon name="ygb-icon-Back-arrow-ic" fontSize="18px" />
                </Button>,
              ]
            : []
        }
      />
      {group2Title && group2Description ? (
        <Hero
          image={group2ImageUrl}
          title={group2Title}
          titleAs="h3"
          description={group2Description}
          actions={
            group2ButtonLink
              ? [
                  <Button
                    key="0"
                    href={group2ButtonLink}
                    appearance="blue"
                    link
                  >
                    {group2ButtonText}{' '}
                    <Icon name="ygb-icon-Back-arrow-ic" fontSize="18px" />
                  </Button>,
                ]
              : []
          }
          reverseColumns
        />
      ) : null}
    </Container>
  );
};
